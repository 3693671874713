import Vue from "vue";
import VueRouter from "vue-router";
// 注入插件
Vue.use(VueRouter)

import BillListPage from '@/page/BillListPage/BillListPage'
import BillDetailPage from "@/page/BillDetailPage/BillDetailPage";
import OauthPage from "@/page/OauthPage/OauthPage";
import WechatProgramLoginPage from "@/page/WechatProgramLoginPage/WechatProgramLoginPage";

// 定义路由
const routes = [
    //添加映射关系
    {
        path: '/',
        name: 'OauthPage',
        component: OauthPage
    },
    {
        path: '/BillListPage',
        name: 'BillListPage',
        component: BillListPage
    },
    {
        path: '/BillDetailPage',
        name:'BillDetailPage',
        component: BillDetailPage
    },
    {
        path: '/WechatProgramLoginPage',
        name: 'WechatProgramLoginPage',
        component: WechatProgramLoginPage
    },
    {
        path: '*',
        component: OauthPage
    },

]

// 创建实例
const router = new VueRouter({
     routes
})

// 导出实例
export default router
