const orderStatus = {
    CREATE: {
        text: "交易中",
        color: "gray",
        detailColor: 'gray',
        detailPriceColor: 'gray',
        positive: true
    },
    SUCCESS: {
        text: "交易成功",
        color: "gray",
        detailColor: 'gray',
        detailPriceColor: 'black',
        positive: false
    },
    FAIL: {
        text: "交易失败",
        color: "red",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    JF: {
        text: "交易超时",
        color: "gray",
        detailColor: 'gray',
        detailPriceColor: 'gray',
        positive: true
    },
    LIMIT: {
        text: "交易失败(补缴)",
        detailText: "交易失败",
        color: "red",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    CONTRACT_NOT_EXIST: {
        text: "交易失败(补缴)",
        detailText: "交易失败",
        color: "red",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    NOTENOUGH: {
        text: "交易失败(补缴)",
        detailText: "交易失败",
        color: "red",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    ON_REFUND_CREATE: {
        text: "退款中",
        color: "gray",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    ON_REFUND_SUCCESS: {
        text: "退款成功",
        color: "gray",
        detailColor: 'gray',
        detailPriceColor: 'black2',
        positive: true,
        addPlus: true,
    },
    ON_REFUND_FAIL: {
        text: "退款失败",
        color: "gray",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true,

    },
    OFF_REFUND_CREATE: {
        text: "人工退款中",
        color: "gray",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    OFF_REFUND_SUCCESS: {
        text: "人工退款成功",
        color: "gray",
        detailColor: 'gray',
        detailPriceColor: 'black',
        positive: true,
        addPlus: true,
    },
    OFF_REFUND_FAIL: {
        text: "人工退款失败",
        color: "gray",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    },
    REQUEST_BLOCKED: {
        text: "交易失败(补缴)",
        color: "red",
        detailColor: 'red',
        detailPriceColor: 'gray',
        positive: true
    }
}
export {
    orderStatus
}
