import Vue from 'vue'
import App from './App'
import router from './router'
import VueRouter from 'vue-router'
// import Vconsole from 'vconsole';
// const vConsole =new Vconsole();

// Vue.use(vConsole);

Vue.use(VueRouter)
// 全局rem
import './common/js/rem'
import './common/font/font.css'
import 'reset-css'

// fastClick
import FastClick from 'fastclick'

FastClick.attach(document.body)
const str = navigator.userAgent.toLowerCase()
const ver = str.match(/cpu iphone os (.*?) like mac os/)
FastClick.prototype.focus = function (targetElement) {
  targetElement.focus()
}
if (!ver) { // 非IOS系统
  // 引入fastclick 做相关处理
  FastClick.attach(document.body)
} else {
  if (parseInt(ver[1]) < 11) {
    // 引入fastclick 做相关处理
    FastClick.attach(document.body)
  }
}

// 引入vant
import { Tab, Tabs } from 'vant'
import { Icon } from 'vant'
import { Image as VanImage } from 'vant';
import { ActionSheet } from 'vant';
import { Toast } from 'vant';
import { Button } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { PullRefresh } from 'vant';
import { List } from 'vant';
import { Dialog } from 'vant'

Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Button);
Vue.use(ActionSheet);
Vue.use(VanImage);
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Dialog)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

