import axios from 'axios'
import { Notify } from 'vant'
axios.defaults.timeout = 10000
// response拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code == 'TOKEN_CHECK_OVERDUE' || response.data.code == 'TOKEN_CHECK_NO_AUTHORITY' || response.data.code == 'AUTH_API_NO_AUTHORITY') {
      console.log("请求结果：sessionId失效"+"sessionId失效")
      // 清空登录记录
      localStorage.removeItem("sessionId");
      // 统一跳到 oauth页面
      var _url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/dxlcbill/#/"
              : "https://lzgj.test.brightcns.cn/dxlcbill/#/";
      Notify({ type: 'danger', message: '登录超时！' });
      setTimeout(() => {
        window.location.replace(_url);
      }, 1000)
    } else {
      return response
    }
  },
  error => {
    return Promise.reject(error)
  }
)

const handleStatus = (result) => {
  // console.log("请求结果："+JSON.parse(result.data.bizResp))
  if (result.status === 200) {
    const a = {}
    a.code = 'SUCCESS'
    if (result.data.bizResp) {
      a.data = JSON.parse(result.data.bizResp)
    }
    a.originalData = {}
    for (const i in result.data) {
      if (i == 'bizResp') {
        a.originalData[i] = JSON.parse(result.data[i])
      } else {
        a.originalData[i] = result.data[i]
      }
    }
    return { res: a, headers: result.headers }
  } else if (result.status === 500) {
    const a = JSON.parse(result.data.bizResp)
    a.code = 'SUCCESS'
    return { res: a, headers: result.headers }
  } else {
    // switch (result.status) {
    // }
    return Promise.reject(result)
  }
}

export default {
  post (url, params) {
    // console.info('post请求地址：' + url)
    // console.info('post请求地址：' + JSON.stringify(params))
    return axios
      .post(url, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(handleStatus)
      .catch(error => {
        return Promise.resolve(error.data)
      })
  }

}
