<template>

  <div class="billList">
    <!-- 公交列表 -->
    <div class="busOrderList">
      <van-pull-refresh v-model="busRefreshing" @refresh="busOnRefresh" class="van-pull-refresh">
        <van-list
            class="busList"
            v-model="busListLoading"
            :finished="busFinished"
            @load="busOnload"
            v-if="busList.length > 0"
            offset="100">
          <div class="busBox">
            <div
                class="busDateItem"
                v-for="(value, key) in busList"
                :key="key">
              <!--                时间--优惠   -->
              <div class="busDateItem_date">
                <span class="dateYM">{{ value.dateFormat }}</span>
                <span class="dateDiscount"
                      v-if="formatDiscountMoney(value.date)">优惠 {{
                    formatDiscountMoney(value.date)
                  }}</span>
                <span class="datePay" v-if="formatPayMoney(value.date)">支出 {{
                    formatPayMoney(value.date)
                  }}</span>
              </div>
              <div
                  v-for="(value2, key2) in value.items"
                  :key="key2"
                  @click="jumpOrderDetailPage(value2.orderId)">
                <div class="busBoxItem">
                  <div class="busBox_1">
                    <img class="busOrderLogo" :src="setIcon(value2.trafficType)">
                    <p class="p1">{{ formatTrafficType(value2.trafficType) }}</p>
                    <p class="p2" :class="formatOrderStatus(value2.orderStatus).color">
                      {{ formatOrderStatus(value2.orderStatus).text }}</p>
                  </div>
                  <div class="busBox_2">
                    <div class="block1">
                      <p v-if="value2.routeName">{{ value2.routeName }}</p>
                      <p v-else>{{ value2.routeCode }}</p>
                      <p>{{ dateFormat(value2.inTime) }}</p>
                    </div>
                    <div class="block2">
                       <p>{{ value2.payWay }}</p>
                       <p>{{ formatPayType(value2.payType) }}</p>
                    </div>
                    <div class="block3">
                      <p>￥</p>
                      <p>{{value2.payAmount}}</p>
                    </div>
                  </div>
                  <div class="btnBox">
                    <div class="div"></div>
                    <div class="normalBtn" v-if="value2.evaluationId" @click.stop="skip2Review(value2)">点评</div>
                    <div class="btn" v-if="
                        (value2.orderStatus == 'OFF_REFUND_FAIL' ||
                         value2.orderStatus == 'ON_REFUND_FAIL' ||
                         value2.orderStatus == 'FAIL' ||
                         value2.orderStatus == 'CONTRACT_NOT_EXIST' ||
                         value2.orderStatus == 'NOTENOUGH' ||
                         value2.orderStatus == 'REQUEST_BLOCKED' ||
                         value2.orderStatus == 'LIMIT')
                          ">去支付
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </van-list>
        <!-- 没有数据 -->
        <div class="noData" v-else>
          <img
              src="../../assets/icon_no_order.png"
              alt=""
          />
          <div class="p1">{{ setEmptyText() }}</div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {busOrderList, couponOrderList} from "@/api/HttpService";
import cloneDeep from "clone-deep";
import {orderStatus} from "@/common/js/orderStatus";

import busIcon from "@/assets/icon_bus.png";
import waterBusIcon from "@/assets/icon_water_bus.png";
import metroIcon from "@/assets/icon_metro.png";

export default {
  data: function () {
    return {
      loading: false,
      //   公交
      busList: [],
      discountList: [],
      busRefreshing: false,
      busListLoading: false,
      busFinished: false,
      busPageNum: 1,
      busPageSize: 10,
      busTotal: 0,
      recordNo: '',//携带值

    };
  },
  methods: {
    // 跳转到公交账单详情
    jumpOrderDetailPage: function (orderId) {
      this.$router.push({
        name: "BillDetailPage",
        query: {
          orderId: orderId,
        },
      });
    },
    // 将一维公交账单列表 转换为二维数组
    changeData: function (list) {
      for (let i in list) {
        let formatDate =
            (list[i].inTime || '').split(" ")[0].split("-")[0] +
            (list[i].inTime || '').split(" ")[0].split("-")[1];
        let formatDateString =
            (list[i].inTime || '').split(" ")[0].split("-")[0] +
            "年" +
            (list[i].inTime || '').split(" ")[0].split("-")[1] +
            "月";
        let match = false;
        let billList = cloneDeep(this.busList);
        for (let j in billList) {
          if (billList[j].date == formatDate) {
            billList[j].items.push(list[i]);
            match = true;
          }
        }
        if (match == false) {
          billList.push({
            date: formatDate,
            dateFormat: formatDateString,
            discount: this.formatDiscountMoney(formatDate),
            items: [list[i]],
          });
        }
        this.busList = billList;
        //todo 测试时用需要删除
        // this.busList[0].items[0].evaluationId=28
      }
    },
    //匹配月份的消费金额
    formatPayMoney: function (formatDate) {
      for (let i in this.discountList) {
        if (this.discountList[i].month.replace("-", "") == formatDate) {
          return "￥" + this.formatMoney(this.discountList[i].payAmountTotal)
        }
      }
    },

    //匹配月份的折扣金额
    formatDiscountMoney: function (formatDate) {
      for (let i in this.discountList) {
        if (this.discountList[i].month.replace("-", "") == formatDate) {
          return "￥" + this.formatMoney(this.discountList[i].discountAmountTotal)
        }
      }
    },
    // 公交账单加载
    busOnload: async function () {
      if (this.recordNo) {
        await this.couponOrderList();
      } else {
        await this.getBusOrders();
      }
      console.log("加载下一页")
    },
    // 公交账单刷新
    busOnRefresh: async function () {
      this.busList = [];
      this.discountList = [];
      this.busPageNum = 1;
      this.busFinished = false;
      this.busRefreshing = true;
      if (this.recordNo) {
        await this.couponOrderList();
      } else {
        await this.getBusOrders();
      }
      this.busRefreshing = false;
    },
    // 将交易状态转为中文(公交)
    formatOrderStatus: function (status) {
      return orderStatus[status];
    },
    //格式化交通方式
    formatTrafficType: function (type) {
      switch (type) {
        case "bus":
          return "公交"
        case "ship":
          return "水上公交"
        case "track":
          return "地铁"
        default:
          return "公交"
      }
    },
    // 将交易类型转换为中文
    formatPayType: function (payType) {
      switch (payType) {
        case "wallet":
          return "余额付款";
        case "wechat":
          return "微信代扣";
        case "unionpay":
          return "云闪付代扣";
        case "lzccb":
          return "柳州银行卡支付";
        case "alipay":
          return "支付宝先乘后付";
        default:
          return "未知";
      }
    },
    //设置icon
    setIcon: function (type) {
      switch (type) {
        case "bus":
          return busIcon
        case "ship":
          return waterBusIcon
        case "track":
          return metroIcon
        default:
          return busIcon
      }
    },
    // 将扣款时间格式化（公交）
    dateFormat: function (date) {
      let month = (new Date().getMonth() - -1).toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      let day = new Date().getDate();
      let year = new Date().getFullYear();
      let now = year + "-" + month + "-" + day;
      if ((date || '').split(" ")[0] == now) {
        return "今天 " + (date || '').split(" ")[1];
      } else {
        return (
            (date || '').split(" ")[0].split("-")[1] +
            "-" +
            (date || '').split(" ")[0].split("-")[2] +
            " " +
            (date || '').split(" ")[1]
        );
      }
    },

    //格式化交易金额正负
    formatSubtract: function (status) {
      switch (status) {
        case "ON_REFUND_SUCCESS":
        case "OFF_REFUND_SUCCESS":
          return "+"
        default:
          return "-";
      }
    },

    //格式化金额
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money)) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
      return value;
    },

    // 获取公交订单列表
    getBusOrders: async function () {
      this.loading = true;
      this.busListLoading = true;
      let params = {
        pageNum: this.busPageNum,
        pageSize: this.busPageSize,
      };
      await busOrderList(params)
          .then(({res}) => {
            // console.log("获取结果是" + JSON.stringify(res.data));
            this.loading = false;
            if (
                res.originalData.bizCode == "SUCCESS" &&
                res.originalData.code == "SUCCESS"
            ) {
              this.busListLoading = false;
              if (res.data.pageData.hasNextPage) {
                console.log("有下一页");
                // 有下一页
                this.busFinished = false;
              } else {
                console.log("没有下一页");
                this.busFinished = true;
              }
              this.changeData(res.data.pageData.list);
              this.discountList.push(...res.data.monthStatistic);
              console.log("月份数据" + this.discountList)
              this.busPageNum++;
            } else {
              this.$toast(res.originalData.bizMsg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.busFinished = true;
            this.busRefreshing = false;
          });
    },
    // 获取折扣卡使用记录
    couponOrderList: async function () {
      this.loading = true;
      this.busListLoading = true;
      let params = {
        couponNo: this.recordNo,
        pageNum: this.busPageNum,
        pageSize: this.busPageSize,
      };
      await couponOrderList(params)
          .then(({res}) => {
            // console.log("获取结果是" + JSON.stringify(res.data));
            this.loading = false;
            if (
                res.originalData.bizCode == "SUCCESS" &&
                res.originalData.code == "SUCCESS"
            ) {
              this.busListLoading = false;
              if (res.data.hasNextPage) {
                console.log("有下一页");
                // 有下一页
                this.busFinished = false;
              } else {
                console.log("没有下一页");
                this.busFinished = true;
              }
              this.changeData(res.data.list);
              // this.discountList.push(...res.data.monthStatistic);
              console.log("月份数据" + this.discountList)
              this.busPageNum++;
            } else {
              this.$toast(res.originalData.bizMsg);
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
            this.loading = false;
            this.busFinished = true;
            this.busRefreshing = false;
          });
    },

    //拿到携带参数
    getParam: function () {
      var url = window.location.href;             //获取当前url
      console.log("获取" + url)
      if (url.indexOf("?") == -1) {
        return this.recordNo = ''
      }
      var cs = url.split('?')[1];                //获取?之后的参数字符串
      console.log("携带的参数值" + cs)
      console.log("携带的参数值split" + cs.split('=')[1])
      if (cs.split('=')[0] == "recordNo") {
        this.recordNo = cs.split('=')[1]
      }
    },

    //设置无行程的文字提示
    setEmptyText: function () {
      if (this.recordNo) {
        return "暂无记录"
      }
      return "您暂无出行记录"
    },
    //跳转到点评界面
    skip2Review(value) {
      var result=JSON.stringify(value)
      var _url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/dxlc-order/#/ReviewPage?dataBean="+result
              : "https://lzgj.test.brightcns.cn/dxlc-order/#/ReviewPage?dataBean="+result;
      window.location.href = _url;
    }
  },


  mounted: async function () {
    this.getParam()
    if (this.recordNo) {
      document.title = "使用记录";
      await this.couponOrderList();
      return
    }
    await this.getBusOrders();
    document.title = "出行账单";
  },
};
</script>

<style lang="scss" scoped>
@import "BillListPage";
</style>
