<template>
  <div></div>
</template>

<script>
// 引入API
import * as Http from "@/api/HttpService.js";

export default {
  data: function () {
    return {};
  },

  mounted: async function () {
    console.log(this.$route.query);
    if (this.$route.query.sessionId) {
      localStorage.removeItem("sessionId");
      let params = {
        chlAppId: "com.brightcns.dxlc.wxmp",
        chlSid: this.$route.query.sessionId,
      };
      await Http.loginS2S(params)
        .then(({ res }) => {
          if (
            res.originalData.bizCode == "SUCCESS" &&
            res.originalData.code == "SUCCESS"
          ) {
            window.localStorage.setItem("sessionId", res.data.sessionId);
            window.localStorage.setItem("hostProgram", "wxmp");
            this.$router.replace({
              name: "BillListPage",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>

<style>
</style>
