import {API} from './HttpConfig'
import http from './HttpUtil'
import * as Gateway from './GatewayConfig'

//测试环境为true 开发环境为false
const IS_RELEASE = true;
const apiHost = IS_RELEASE ? '/gateway' : 'https://lzgj.brightcns.com/gateway';

const MD5KEY = 1234567890
const MD5 = require('js-md5')


// 获取网关参数
function getGatewayParams(method, params) {
    const obj = {}
    obj.appId = Gateway.appId
    obj.signType = Gateway.signType
    obj.sign = Gateway.sign
    obj.version = '2.0'
    obj.sessionId = localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : ''
    // obj.sessionId = "44cd55c5f34edc16a5da60194a48bcfa"
    obj.timestamp = Date.parse(new Date())
    obj.method = method
    obj.bizReq = JSON.stringify(params)
    return obj
}

// 获取网关参数
function getGatewayParams2(method, params) {
    const obj = {}
    obj.appId = Gateway.appId
    obj.signType = Gateway.signType
    obj.sign = Gateway.sign
    obj.version = '1.0'
    obj.sessionId = localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : ''
    // obj.sessionId = '44cd55c5f34edc16a5da60194a48bcfa'
    obj.timestamp = Date.parse(new Date())
    obj.method = method
    obj.bizReq = JSON.stringify(params)
    return obj
}

// 获取网关参数   appId  dxlc
function getGatewayParams3(method, params) {
    const obj = {}
    obj.appId = 'com.brightcns.dxlc'
    obj.signType = 'MD5'
    obj.sign = Gateway.sign
    obj.version = '2.0'
    obj.sessionId = localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : ''
    // obj.sessionId = '03ce646d142f01c70e2ffc018d9f8f17'
    obj.timestamp = Date.parse(new Date())
    obj.method = method
    obj.bizReq = JSON.stringify(params)
    obj.sign = signFunc(obj)
    return obj
}

// 签名方法
function signFunc(obj) {
    let str = ''
    // 如果sessionId不等于空
    if (obj.sessionId != '') {
        str += `appId=${obj.appId}&bizReq=${obj.bizReq}&method=${obj.method}&sessionId=${obj.sessionId}&signType=${obj.signType}&timestamp=${obj.timestamp}&version=${obj.version}${MD5KEY}`
    }
    // 否则的话
    else {
        str += `appId=${obj.appId}&bizReq=${obj.bizReq}&method=${obj.method}&signType=${obj.signType}&timestamp=${obj.timestamp}&version=${obj.version}${MD5KEY}`
    }
    console.log(str)
    const md5 = MD5(str)
    return md5
}

// appId=>h5
const POST = (method, params) => {
    const param = getGatewayParams(method, params)
    return http.post(apiHost, param)
}

//  appId=>h5
const POST2 = (method, params) => {
    const param = getGatewayParams2(method, params)
    return http.post(apiHost, param)
}

//  appId=>dxlc  需要加签
const POST3 = (method, params) => {
    const param = getGatewayParams3(method, params)
    return http.post(apiHost, param)
}

//乘车账单列表
export const busOrderList = (param) => {
    return POST(API.busOrderList, param)
}

// 折扣卡使用记录
export const couponOrderList = (param) => {
    return POST2(API.couponOrderList,param)
}

//乘车账单详情
export const busOrderDetail = (param) => {
    return POST2(API.busOrderDetail, param)
}

//优惠券使用查看乘车账单详情
export const busCouponOrderDetail = (param) => {
    return POST2(API.busCouponOrderDetail, param)
}

// 通过小程序sessionId换取sessionId
export const loginS2S = (param) => {
    return POST2(API.loginS2S, param);
}

//补缴
export const deductOrder = (param) => {
    return POST2(API.deductOrder,param)
}

export const post2 = (param) => {
    return POST2(API.deductOrder,param)
}
export const post3 = (param) => {
    return POST3(API.deductOrder,param)
}





